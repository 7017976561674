<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ event.id ? 'Update': 'Add' }} Event
          </h5>
          <div>
            <feather-icon
              v-if="event.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="saveDate"
            @reset.prevent="resetForm"
          >
{{event}}
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Category"
              rules="required"
            >
              <b-form-group
                label="Category"
                label-for="event-title"
              >
                 <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="event.category_id"
                label="type"
                :options="categoriesList"
                :reduce="(val) => val.id"
                @input="getCoursesByCategory(event.category_id)"
              />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Calendar -->
            <validation-provider
              #default="validationContext"
              name="Calendar"
              rules="required"
            >

              <b-form-group
                label="Course"
                label-for="calendar"
             
              >
               <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="event.course_id"
                label="name"
                :options="coursesList"
                :reduce="(val) => val.id"
                >
                <template #no-options="{ search, searching, loading }">
                  No data ...
    </template>
  </v-select>

                

               
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules="required"
            >

              <b-form-group
                label="Start Date"
                label-for="start-date"
            
              >
                <flat-pickr
                  v-model="event.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
             
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules="required"
            >

              <b-form-group
                label="City"
                label-for="end-date"
               
              >
                 <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="event.city"
                   multiple
                label="name"
                :options="citiesList"
                :reduce="(val) => val.id"
              />

               
              </b-form-group>
            </validation-provider>

           
         

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ event.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs,reactive } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import store from "@/store";
import Vue from "vue";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetevent` from `useCalendarEventHandler` composition function.
     * Once we get `resetevent` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
     const addScheduleForm = reactive({
      category_id: "",
      city: [],
      course_id: "",
    });
    const coursesList = ref([]);
    const saveDate = () => {
          console.log("event",props.event)
//        const formData = new FormData();
//        formData.append("date", props.event.start);
// formData.append("course_id", event.course_id);
// formData.append("city_id", JSON.stringify(event.city));
// formData.append("online",0);

// formData.append("category_id", addScheduleForm.category_id);
      // console.log("formData",formData)
    emit('add-event', props.event)
    
  //       store.dispatch("schedule/addDate",formData).then((response) => {
  //     console.log("response", response.data);
  //        Vue.swal({
  //                      title: "Date Added ",
  //                 icon: "success",
  //                 customClass: {
  //                   confirmButton: "btn btn-primary",
  //                 },
  //                 buttonsStyling: false,
  //               });
  
  //  }).catch((error) => {
      
  //           Vue.swal({
  //             title: "",
  //             text: `${error.response.data.message}`,
  //             icon: "error",
  //             confirmButtonText: "Ok",
  //             customClass: {
  //               confirmButton: "btn btn-primary",
  //             },
  //             buttonsStyling: false,
  //           });
  //         });
     
    };

    const categoriesList = ref([]);
    const citiesList = ref([]);

    store.dispatch("cities/getAllcitites").then((response) => {
      console.log("response", response.data.data);
      citiesList.value = response.data.data;
    });

    const getCoursesByCategory = (id) => {
      addScheduleForm.course_id = "";
      store
        .dispatch("categories/getCoursesByCategory", {
       
    
      ' category_id':id

   
   })
        .then((response) => {
          console.log("response", response.data);
          coursesList.value = response.data.data;
        });
    };
    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      categoriesList.value = response.data.data;
    });
    const clearFormData = ref(null)

    const {
      event,
      resetevent,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
   
      resetForm,
      clearForm,
    } = formValidation(resetevent, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      event,
      calendarOptions,
      onSubmit,
      guestsOptions,
getCoursesByCategory,
      // Form Validation
      resetForm,
      refFormObserver,
     
      saveDate,
         coursesList,
      categoriesList,
      citiesList,
         addScheduleForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
